import React, {  useState, useEffect } from "react";
import styled from 'styled-components';
import TitleHome from "../../../components/TitleHome";
import { DataSteps } from "../constants/index";
import AOS from "aos";
import { Container } from "reactstrap";
import Slider from "react-slick";


const Currencies = () => {

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

    const renderItem = (element) => {
        return (
          <ItemProfit>

              <ContentItem>
                  <TitleItem>{element.title}</TitleItem>
                  <DrepItem>{element.dreption}</DrepItem>
              </ContentItem>
          </ItemProfit>
        );
      };

      const settings = {
        dots: true, // Hiển thị chấm tròn dưới
        infinite: true, // Chế độ lặp vô hạn
        speed: 500, // Tốc độ chuyển slide
        autoplay: true, // Tự động chuyển slide
        autoplaySpeed: 1500, // Tốc độ tự động
        slidesToShow: 6, // Số phần tử hiển thị trên PC
        slidesToScroll: 1, // Số phần tử chuyển mỗi lần
        responsive: [
          {
            breakpoint: 1200, // Màn hình máy tính bảng
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 768, // Màn hình điện thoại
            settings: {
              slidesToShow: 3,
            },
          },
        ],
        arrows: false, // Ẩn nút prev/next
      };



    return (
        <Box_Slide>
          <Container className="zdf">
            <TitleHome stlef="color-title2" title="Our Currencies" drep="Rapidiously morph transparent internal develop economically sound core competencies sources whereas resource sucking e-business. Conveniently innovate compelling internal."/>
            <GroupAbout></GroupAbout>
            <StyledSlider {...settings}>
          <ItemProfit>
            <ContentItem>
              <p>
                <img src="./ic5.png" alt="icon" />
              </p>
            </ContentItem>
          </ItemProfit>
          <ItemProfit>
            <ContentItem>
              <p>
                <img src="./ic5.png" alt="icon" />
              </p>
            </ContentItem>
          </ItemProfit>
          <ItemProfit>
            <ContentItem>
              <p>
                <img src="./ic5.png" alt="icon" />
              </p>
            </ContentItem>
          </ItemProfit>
          <ItemProfit>
            <ContentItem>
              <p>
                <img src="./ic5.png" alt="icon" />
              </p>
            </ContentItem>
          </ItemProfit>
          <ItemProfit>
            <ContentItem>
              <p>
                <img src="./ic5.png" alt="icon" />
              </p>
            </ContentItem>
          </ItemProfit>
          <ItemProfit>
            <ContentItem>
              <p>
                <img src="./ic5.png" alt="icon" />
              </p>
            </ContentItem>
          </ItemProfit>
          <ItemProfit>
            <ContentItem>
              <p>
                <img src="./ic5.png" alt="icon" />
              </p>
            </ContentItem>
          </ItemProfit>

        </StyledSlider>
            <div className="grbtntrade">
              <a className="btntrade" target="_blank" href="/">Trade Now</a>
            </div>
            </Container>
            {/* <video className="abst" width="100%" height="auto" autoPlay loop muted>
              <source src="./vio.mp4" type="video/mp4" />
            </video> */}
        </Box_Slide>
    );
  }





  const StyledSlider = styled(Slider)`
  margin-bottom:2.5rem;
  .slick-dots {
    bottom: -15px;
    color: #ffffff;
  }
  .slick-dots li button:before {
    color: #ffffff;
    font-size:14px;
  }
  .slick-dots li.slick-active button:before {
    color: #fab128; /* Màu cho dấu chấm đang hoạt động */
  }

`;


  const Box_Slide = styled.section`
    position: relative;
    padding:80px 0;
    width:100%;
    background: url('./bkk.svg');
    background-size: cover;
    background-position: top center;

    .grbtntrade{
      text-align: center;
    }

    .btntrade{
      border-radius: 30px;
      cursor: pointer;
      color: rgb(0, 0, 0);
      font-weight: bold;
      background: #FDE08D;
      background: -webkit-linear-gradient(top, #8f6B29, #FDE08D, #DF9F28);
      background: linear-gradient(top, #8f6B29, #FDE08D, #DF9F28);
      border: #8f6B29 2px solid;
      padding:12px 30px;
      margin:auto;
      transition: all .3s ease-in-out;

      &:hover{
        background: -webkit-linear-gradient(top,#418f29,#FDE08D,#bafd82);
        opacity:.6
      }
    }

    .zdf{
      z-index:1!important;
      position: relative!important;
    }
    .abst{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      pointer-events: none;
      height: 100%;
      width: 100%;
      object-fit: cover;

      @media (max-width: 770px) {

      }
    }
`

const ItemProfit = styled.div`
    width:100%;
    transition: all .2s ease;
    text-align: center;
    cursor: pointer;
`

const Icon = styled.div`
  height: 65px;
  width: 65px;
  color: #9629e6;
  text-align: center;
  box-shadow: 0 4px 15px 0 rgb(52 27 159 / 10%);
  border-radius: 4px;
  transition: all .3s ease-in-out;
  margin-bottom: 0;
  margin-right: 30px;
  background: #fff;
  padding: 15px;
  margin:0 auto;

  img{
      width:100%;
  }

`

const ContentItem = styled.div`
  position: relative;

  img{
    max-width:105px;
    width:100%;
  }
`

const TitleItem = styled.p`
  color:#000;
  font-weight:600;
  margin-bottom:0px;
  font-size: 20px;
  font-family: "Titan One", sans-serif;

`

const DrepItem = styled.p`
  color:#000;
`

const ListCom = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom:25px;

  @media (max-width: 770px) {
    flex-wrap: wrap;
  }
`


const GroupAbout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  padding:0 25px;
  margin-bottom:30px;

  @media (max-width: 770px) {
    flex-wrap: wrap;
  }
`



export default Currencies;
