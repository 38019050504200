import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Container, Row, Col } from "reactstrap";
import TitleHome from "../TitleHome"

const OurClient = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 700,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <OurSlick>
      <Container>
      <TitleHome title="Partners and Ecosystem" drep="Rapidiously morph transparent internal develop economically sound core competencies sources whereas resource sucking e-business. Conveniently innovate compelling internal."/>
        <DivOurClient>
          <Slider {...settings}>
            <OurSlickImages>
              <p>
                <img src="./btc2.svg" alt="BTC" />
              </p>
            </OurSlickImages>
            <OurSlickImages>
              <p>
                <img src="./m-eth.svg" alt="ETH" />
              </p>
            </OurSlickImages>
            <OurSlickImages>
              <p>
                <img src="./m-usdt.svg" alt="USDT" />
              </p>
            </OurSlickImages>
            <OurSlickImages>
              <p>
                <img src="./m-t.svg" alt="COIN" />
              </p>
            </OurSlickImages>
            <OurSlickImages>
              <p>
                <img src="./m-eth.svg" alt="ETH" />
              </p>
            </OurSlickImages>
            <OurSlickImages>
              <p>
                <img src="./m-usdt.svg" alt="USDT" />
              </p>
            </OurSlickImages>
            <OurSlickImages>
              <p>
                <img src="./m-t.svg" alt="COIN" />
              </p>
            </OurSlickImages>
            <OurSlickImages>
              <p>
                <img src="./aave.png" alt="COIN" />
              </p>
            </OurSlickImages>

          </Slider>
        </DivOurClient>
      </Container>
    </OurSlick>
  );
};

const DivOurClient = styled.div`
  .slick-slider {
    z-index: 10;
  }
`;

const OurSlick = styled.div`
  position: relative;
  padding: 50px 0;
  overflow: hidden;

  @media only screen and (max-width: 770px) {
    padding: 25px 0;
  }
`

const OurSlickImages = styled.div`
  z-index: 10;
  padding: 20px;
  text-align: center;

  p{
    img{
      max-width:120px;
      width:100%;
      overflow: hidden;
      display:block;
    }
  }
`

export default OurClient;
