import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
import HeaderContentLeft from "./HeaderContentLeft";
import HeaderContentRight from "./HeaderContentRight";
function Header(props) {
  let { styleBg } = props;
  // console.log(styleBg);
  return (
    <HeaderScroll>
      <HeaderTop styleBg={styleBg} className="HeaderTop">
        <Container>
          <HeaderContent>
            <HeaderContentLeft />
            <HeaderContentRight styleBg={styleBg} />
          </HeaderContent>
        </Container>
      </HeaderTop>
    </HeaderScroll>
  );
}

const HeaderScroll = styled.div``;

const HeaderTop = styled.header`
  position: ${(props) => (props.styleBg ? "fixed" : "absolute")};
  background: ${(props) =>
    props.styleBg ? "#000000" : "transparent"};
  padding: 9px 0px;
  width: 100%;
  z-index: 9999 !important;
  transition: ease all 0.5s;
  @media screen and (max-width: 769px) {

  }
`;
const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media screen and (max-width: 769px) {
    margin: 0px;
  }
  @media screen and (max-width: 476px) {
    margin: 0px;
  }
`;
export default Header;
