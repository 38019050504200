import React, {  useState } from "react";
import styled from 'styled-components';
import { DataAbout } from "../constants/index";
import TitleHome from "../../../components/TitleHome";
import { Container, Row, Col } from "reactstrap";


const Buys = () => {

  const renderItem = (element) => {

  };

    return (
        <Box_home>
          <Container>
          <TitleHome title="Steps for you to become" drep=""/>
          <TitleHome title="a part of us" />
            <GroupAbout>
                  <Content>
                    <p>Add network base to your wallet ( Trust or Metamask )</p>
                  </Content>
            </GroupAbout>
            <GroupAbout>
                  <Content>
                    <p>Prepare an amount of ETH on Base,
you can use bridge.base.org to transfer ETH from ether to Base. Remember to always prepare some gas fees to be able to trade
</p>
                  </Content>
            </GroupAbout>
            <GroupAbout>

                  <Content>
                    <p>Buy $GOATB - We have not listed $GOATB on any cryptocurrency space. To buy $GOATB, please send your wallet information and contribution to the project Owner's wallet.</p>
                  </Content>
            </GroupAbout>
            <GroupAbout>
                  <Content>
                    <p>Add $GOATB to your Wallet.</p>
                  </Content>
            </GroupAbout>
          </Container>
        </Box_home>
    );
  }







const Box_home = styled.section`
  position: relative;
  padding:50px 0;
  width:100%;
  /* background: url('./bga3.png'); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height:80vh;

  .group-link-contract{
    max-width: 150px;
    width: 100%;
    border-radius: 40px;
    padding: 5px;
    background: #3F51B5;
  }

  ::after{
    position: absolute;
    content: '';
    width: 200px;
    height: 200px;
    background: url(./c1.svg);
    bottom: 0;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  ::before{
    position: absolute;
    content: '';
    width: 200px;
    height: 200px;
    background: url(./c1.svg);
    bottom: 0;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 770px) {
    background:none;
  }
  }
`






const ListCom = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`

const Thumbnail = styled.div`
  max-width:1100px;
  width: 100%;
  text-align: center ;

  img{
    width:100%;
    max-width: 250px;
  }

  @media (max-width: 770px) {
    max-width:100%;
    margin-right:0;
  }
`




const Content = styled.div`
  position: relative;
  max-width: 800px;
  color: #000;
  border: solid 2px #ffffff;
  padding: 25px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  background: #fff;
  border-top-left-radius: 60px;
  border-bottom-right-radius: 60px;
  min-height: 140px;

  @media (max-width: 770px) {
    width:100%;
  }

  p{
    text-align: justify;
    color:#000;
    font-size: 22px;

  }

`



const DrepItem = styled.p`
  color:##707070;
`


const GroupAbout = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 770px) {
    flex-wrap: wrap;
  }
`








export default Buys;
