import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-tabs/styles.css";

import SliderTop from "../../components/Slider";
import ScrollTop from "../../components/ScrollTop";
import { useWindowScroll } from "react-use";
import OurClient from "../../components/OurClient/OurClient";
import About from "./components/About"
import Currencies from "./components/Currencies"
import Packages from "./components/Packages"
import Referral from "./components/Referral";
import EarnUp from "./components/EarnUp";
import Excellents from "./components/Excellents";
import AboutTop from "./components/AboutTop";
import Social from "./components/Social";
import Buy from "./components/Buy";
import Buys from "./components/Buys";
import ItWork from "./components/ItWork";
import Community from "./components/Community";
import AppCo from "./components/AppCo";



const Home = () => {

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);


  const { y: pageOffset } = useWindowScroll();
  const [visible, setVisibility] = useState(false);
  useEffect(() => {
    if (pageOffset > 400) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  }, [pageOffset]);
  return (
    <Box_home>
      {/* <SliderTop /> */}
      <About ref={section1Ref} id="#section1" />
      <div className="bscok-yellow stipe-value-slider">
      <coingecko-coin-price-marquee-widget
        coin-ids="bitcoin,ethereum,eos,ripple,litecoin"
        currency="usd"
        background-color="transparent"
        locale="en"
        dark-mode="true"
      ></coingecko-coin-price-marquee-widget>
    </div>
      {/* <Buys /> */}
      <AboutTop/>
      <Currencies/>
      <ItWork/>
      <Excellents/>
      <AppCo />
      {pageOffset > 100 ? <ScrollTop /> : ""}
    </Box_home>
  );
};
const Box_home = styled.section`
  position: relative;
  overflow: hidden;
  background: #ffffff;
  background-attachment: fixed;
  background-size: cover;
  background-position: top center;

  @media (max-width: 770px) {
    min-height:50vh;
  }

  .bscok-yellow{
    background:#fff5c0;
  }

`;
const Title_home = styled.h2`
  color: ${(props) => props.color};
  font-size: 40px;
  margin-bottom: 24px;
  font-weight: bold;
  line-height: 1.1;
`;

export default Home
