import React from 'react'
import styled from 'styled-components'

const Text = () => {
  return (
    <TextFooter>© 2020 By Smart</TextFooter>
  )
}

const TextFooter = styled.p`
    color:#4c4c4c;
    text-align: center;
`


export default Text
