import React, {  useState, useEffect } from "react";
import styled, { keyframes } from 'styled-components';
import { DataAbout } from "../constants/index";
import TitleHome from "../../../components/TitleHome";
import StarryCanvas from "../../../components/StarryCanvas/StarryCanvas";
import { Container, Row, Col } from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";



const colorChange = keyframes`
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
`;


const About = () => {

  const renderItem = (element) => {
    return (
      <ItemAbout>
          <Icon>
              <img src={element.icon} alt="" />
          </Icon>
          <ContentItem>
              <TitleItem>{element.title}</TitleItem>
              <DrepItem>{element.dreption}</DrepItem>
          </ContentItem>
      </ItemAbout>
    );
  };

  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

    return (
        <Box_home id="#section1">
          <Container className="zdf">
            <GroupAbout>
                <div className="tbcontent" data-aos="fade-right">
                      <h3 className="titletop"> <AnimatedText>Secure & Easy Way</AnimatedText> Access the markets your way with innovative <strong>BIGSTARS</strong> Platform</h3>
                      <p className="drptop">Claim your $100 deposit bonus for new <strong>BIGSTARS</strong> accounts with a minimum deposit of $1,000*.</p>
                      <a className="btntrade" href="https://trade.bigstars.vip/register">Register Now 
                      {/* <i className="in-appsr ti-arrow-circle-right"></i> */}
                      </a>
                 </div>
                 <div className="tbImg" data-aos="fade-down">
                    <img src="./sul.svg" alt="" />
                 </div>
            </GroupAbout>
          </Container>
          {/* <video className="abst" width="100%" height="auto" autoPlay loop muted>
              <source src="./hlg.mp4" type="video/mp4" />
            </video> */}
            <StarryCanvas />
        </Box_home>
    );
  }







const Box_home = styled.section`
  position: relative;
  padding:150px 0;
  width:100%;
  /* background: url('./bkk.svg'); */
  background:#000000;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @media (max-width: 770px) {
    min-height:45vh;
    padding:80px 0;
  }

  .abst{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      pointer-events: none;
      height: 100%;
      width: 100%;
      object-fit: cover;

      @media (max-width: 770px) {

      }
    }

    .zdf{
      z-index:1!important;
      position: relative!important;
    }
    .abst{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      pointer-events: none;
      height: 100%;
      width: 100%;
      object-fit: cover;

      @media (max-width: 770px) {

      }
    }

    ::after{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: #0000008d;
    bottom: 0;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

`



const ItemAbout = styled.div`
    display:flex;
    margin-bottom:15px;
    align-items: center;
`


const ListCom = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`

const Thumbnail = styled.div`
  max-width:750px;
  width: 100%;
  margin-right:45px;

  img{
    width:100%;
  }

  @media (max-width: 770px) {
    max-width:100%;
    margin-right:0;
  }
`

const Icon = styled.div`
  height: 65px;
  width: 65px;
  color: #fff;
  text-align: center;
  box-shadow: 0 4px 15px 0 rgb(52 27 159 / 10%);
  border-radius: 4px;
  transition: all .3s ease-in-out;
  margin-bottom: 0;
  margin-right: 30px;
  background: #fff;
  padding: 15px;

  img{
      width:100%;
  }

`

const ContentItem = styled.div`
  position: relative;
`

const Content = styled.div`
  position: relative;
  width: calc(100% - 645px);
  color:#fff;

  @media (max-width: 770px) {
    width:100%;
  }

`

const TitleItem = styled.p`
  color:#9629e6;
  font-weight:600;
  margin-bottom:5px

`

const DrepItem = styled.p`
  color:##fff;
`


const GroupAbout = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 770px) {
    flex-wrap: wrap;
  }

  .btntrade{
      border-radius: 30px;
      cursor: pointer;
      color: rgb(0, 0, 0);
      font-weight: bold;
      background: #FDE08D;
      background: -webkit-linear-gradient(top, #8f6B29, #FDE08D, #DF9F28);
      background: linear-gradient(top, #8f6B29, #FDE08D, #DF9F28);
      border: #8f6B29 2px solid;
      padding:12px 30px;
      transition: all .3s ease-in-out;
      margin:auto;

      .in-appsr{
        opacity:0;
        transition: all .3s ease-in-out;
        font-size:18px;
        color:#ffffff;
        font-weight:bold;
      }

      &:hover{
        color:#ffffff;
        .in-appsr{
          opacity:1;
        }
      }
  }

  .titletop{
    font-size:50px;
    color:#ffffff;
    font-weight:300;
  }
  .drptop{
    font-size:16px;
    color:#ece8e8;
    margin-bottom:25px;
  }
  .tbcontent{
    width:60%;
    @media (max-width: 1025px) {
      order:2;
      width:100%;
      text-align: center;
    }
  }
  .tbImg{
    width:40%;

    img{
      max-width:350px;
    }
    @media (max-width: 1025px) {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;

      img{
        max-width:250px;
      }
    }
  }
`


const AnimatedText = styled.span`
  font-weight: bold;
  background: linear-gradient(270deg, #ffc400, yellow, #ffe065, #fff5c0, #ffffff);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${colorChange} 2s infinite linear;
`;






export default About;
