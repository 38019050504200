import React, {  useState } from "react";
import styled from 'styled-components';


  class LanguageSwitcherSelector extends React.Component {

    
    onChange = e =>{
      this.props.handleChangeLanguage(e.target.className);
    }
    
    render() {
      const options = languages.map(language => {
        if(language.code != this.props.lang){
          return <li onClick={this.onChange}><div value={language.code} className={language.code} ></div></li>
        }  
      });

      const languages = [
        { code: 'en', name: 'English'},
        { code: 'rus', name: 'Русский'},
        { code: 'deu', name: 'Deutsch'}
      ]
      
      return (
        <div className="lang"> 
          <div 
            className={this.props.lang}
          >
          </div>
          <ul class="dropdown" >
            {options}
          </ul>
        </div>
      )
    }
  }






  const Box_home = styled.section`
    position: relative;
    padding:45px 0;
    width:100%;
`

const ItemProfit = styled.div`
    margin-bottom:15px;
    max-width:255px;
    width:100%;
    background:#fff;
    border: 1px solid #eff2f7;
    transition: all .2s ease;
    box-shadow: 0 3px 20px 0 rgb(0 0 0 / 12%);
    margin-right:15px;
    border-radius:5px;
    text-align: center;
    cursor: pointer;

    :hover{
        will-change: transform;
        background: #6730e3;
        color: #ffffff;
        margin-top:-10px;
    }


`

const BtnPrice = styled.a`
  border-radius: 30px;
  color: #6730e3;
  border: 2px solid #6730e3;
`

const Price = styled.p`
  color: #9629e6;
  font-size:28px;
  margin-bottom:10px
`

const TitleItem = styled.p`
  color:#9629e6;
  font-weight:600;
  margin-bottom:5px

`

const DrepItem = styled.p`
  color:##707070;
`

const ListCom = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`

export default LanguageSwitcherSelector;
