import React from "react";
import styled from "styled-components";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import MenuDropdown from "../MenuDropdown";
import HeaderContentNav from "../Header/HeaderContentRight/Component/HeaderContentNav";
function Overlay(props) {
  let { showMenuRes, handleHideMenuRes } = props;
  function handleClickBtnClose() {
    handleHideMenuRes(false);
  }
  return (
    <OverlayComponent showMenuRes={showMenuRes}>
      <WrapperCloseIcon onClick={handleClickBtnClose}>
        <MdClose color="#ffffff" size="1.8rem" />
      </WrapperCloseIcon>
      <WrapperNavOVL>
        <HeaderContentNav showMenuRes={showMenuRes} />
      </WrapperNavOVL>
      <SocialOverLay>
        <WrapperSocial>
          <FaFacebookF className="effectFB" size="1.1em" color="#ffffff" />
        </WrapperSocial>
        <WrapperSocial>
          <AiOutlineTwitter className="effectTW" size="1.1em" color="#ffffff" />
        </WrapperSocial>
        <WrapperSocial>
          <FaLinkedinIn className="effectLIN" size="1.1em" color="#ffffff" />
        </WrapperSocial>
        <WrapperSocial>
          <AiOutlineGooglePlus
            className="effectGOG"
            size="1.1em"
            color="#ffffff"
          />
        </WrapperSocial>
      </SocialOverLay>
      <WrapperText>
        <p className="text-center text-white">© 2021 by Smart</p>
      </WrapperText>
    </OverlayComponent>
  );
}
const OverlayComponent = styled.div`
  width: 100%;
  position: fixed;
  height: 100vh;
  z-index: 999 !important;
  top: 0;
  left: 0;
  background: #1fb4f9;
  opacity: ${(props) => (props.showMenuRes ? "1" : "0")};
  visibility: ${(props) => (props.showMenuRes ? "visible" : "hidden")};
  transition: 0.5s all;
`;
const WrapperNavOVL = styled.div`
  margin-top: 200px;
`;
const SocialOverLay = styled.div`
  max-width: 200px;
  margin: 200px auto 20px auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: 476px) {
    margin: 60px auto 20px auto;
  }
`;
const WrapperSocial = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  &:hover {
    cursor: pointer;
    transition: 0.5s all;
    background-color: #ffffff;
  }
  &:hover .effectFB {
    transition: 0.5s all;
    color: #4267b2 !important;
  }
  &:hover .effectTW {
    transition: 0.5s all;
    color: #1da1f2 !important;
  }
  &:hover .effectLIN {
    transition: 0.5s all;
    color: #0077b5 !important;
  }
  &:hover .effectGOG {
    transition: 0.5s all;
    color: #db4437 !important;
  }
`;
const WrapperCloseIcon = styled.div`
  position: relative;
  top: 20px;
  left: 95%;
  cursor: pointer;
  @media screen and (max-width: 476px) {
    top: 25px;
    left: 80% !important;
  }
`;
const BoxWrapper = styled.div`
  padding: 30px 20px;
  right: -633%;
  position: absolute;
  width: 1350px;
  top: 60%;
  min-height: 300px;
  z-index: 9999;
  margin-top: 9px;
  display: none;
  border-radius: 10px;
  background: rgb(1 2 3 / 68%);
  @media screen and (max-width: 476px) {
    &.wrapperDrop__OVL {
      display: none !important;
    }
  }
`;
const MenuItem = styled.li`
  &.service__itemOVL {
    position: relative;
    padding: 40px 0px;
  }
  &.service__itemOVL:hover .wrapperDrop__OVL {
    display: block !important;
  }
  &.service__itemOVL a.link__service:after {
    height: 2.5px;
    top: 63%;
  }
  @media screen and (max-width: 476px) {
    padding: 0px !important;
    & .dropIcon {
      display: none;
    }
    & a.link__service:after {
      top: 100% !important;
    }
    &.service__itemOVL:hover .wrapperDrop__OVL {
      display: none !important;
    }
  }
`;
const WrapperText = styled.div``;
export default Overlay;
