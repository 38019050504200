import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

const Breadcrumbs = (props) => (
  <BoxCate>
    <Container>
      <h2>{props.title}</h2>
      <p>{props.text}</p>
    </Container>
  </BoxCate>
);
const BoxCate = styled.section`
  position: relative;
  padding: 100px 0;
  background-image: url("/bred.jpeg");
  color: #fff;
  background-attachment: fixed;
  z-index:0;


  ::before{
    position :absolute;
    top:0;
    z-index:-1;
    content:'';
    width:100%;
    height:100%;
    background-image: linear-gradient(to right,rgb(36 36 37 / 84%),rgb(8 8 10 / 81%),rgb(14 13 16 / 82%));
  }


  h2 {
    font-size: 32px;
  }
  p {
    margin-top: 18px;
  }

`



export default Breadcrumbs;
