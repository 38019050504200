import React, { useState, useEffect, } from "react";
import styled from 'styled-components'
import { Container, Row, Col } from "reactstrap";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import TabAddres from "./Component/TabAddres";


const PopupDeposit = () => {
  

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }



  function closeModal() {
    setIsOpen(false);
  }




  return (

    
    <div>
    <BtnDepo onClick={openModal}>Deposit</BtnDepo>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      className="modaldeposit"
      overlayClassName="overlaydepo"
    >
      <BtnClose onClick={closeModal}>x</BtnClose>
      <TitleDepo>Deposit</TitleDepo>
      <ThumCoin><img src="./m-usdt.svg" alt="usdt"/>USDT</ThumCoin>
      <TabAddres/>
      <br/>
      <p>Minimum deposit is 5 USDT</p>
    </Modal>
  </div>
  )
}

const Box_home = styled.section`
  position: relative;
  overflow: hidden;
  min-height:100vh;
  background: url("/images/bn4.jpeg");
  z-index:0;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  
`



const TitleDepo = styled.h3`
  font-size:22px;
  margin-bottom:25px;
  color:#fff;
  border-bottom: 1px solid #575465;
  padding-bottom: 10px;
`

const BtnDepo = styled.button`
  color:blue;
`

const BtnClose = styled.button`
  position: absolute;
  right: 20px;
  display: block;
  width: 30px;
  height: 30px;
  background: transparent;
  color: #f85318;
  font-size: 25px;
  top: 10px;
`



const ThumCoin = styled.p`
  font-size:18px;
  display:flex;
  align-items: center;
  width: 100px;
  height: 30px;
  background:#fff;
  border-radius:25px;
  margin-bottom:25px;
  justify-content: center;
  color:#000;
  

  img{
    max-width:20px;
    width:100%;
    marign-right:5px;
  }
`



export default PopupDeposit
