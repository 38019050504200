import React, { useState, useEffect, } from "react";
import styled from 'styled-components'
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumbs";


const About = () => {



  return (
    <Box_home>
      <Breadcrumbs title="Abouts us" text="AppCo is a trading platform" />
      <Container>
      <Content>
          <p>AppCo is a trading platform that applies the BINARY OPTION platform, which is currently the most transparent trading platform in the world, participants on the exchange will trade by themselves, not by winning or losing transactions with brokers of the exchange. or Market Makers.</p>
          <p>We are focused on serving our customers responsibly with the latest innovations in technology. instruments on forex and crypto exchanges, research tools to support transactions on the BO and blockchain platforms.</p>
          <h3>WHY SHOULD CHOOSE AppCo TO TRADE</h3>
          <p>Simple, Friendly Interface: AppCo 's BO platform has a groundbreaking design, is very sophisticated and fully functional so that anyone can start getting acquainted and trading without financial experience. before.</p>
          <p>Easy Trading -Traders: AppCo can earn profits within 60s, by directly updating the current price trend of the market in seconds.</p>
          <p style={{ textAlign: 'center' }}><img src="./trade-ab.jpeg" alt=""/></p>
          <p>Attractive Profits: AppCo offers investors the highest returns on the world's leading financial platforms, with a profit of up to 95% on capital when winning and you do not lose any fees. any other.</p>
          <p>Rapid Liquidation: AppCo will help you recharge or withdraw profits flexibly and instantly via crypto gateways like BTC, ETH, USDT</p>
          <p>Synchronize All Devices: You can access and trade easily with your laptop, tablet or phone, and can be traded anywhere, anytime around the world.</p>
          <p>The Power of Blockchain: The entire system of AppCo is operated based on blockchain technology, giving investors complete peace of mind about stability, sustainability and security when participating in transactions.</p>
          <p>Also: We not only give traders the opportunity to make money, but also we teach them how to make more than money. Our team has world-class analysts. They develop early trading strategies and teach traders how to use them wisely in open webinars, and they consult directly with traders.</p>
      </Content>
      </Container>
  </Box_home>
  )
}

const Box_home = styled.section`
  position: relative;
  
  
`


const Content = styled.div`
  width:100%;
  padding:50px 0;
`

const TitleAcount = styled.h3`
  font-size:40px;
  color:#fff;
`


const DrepAcount = styled.p`
  font-size:18px;
  color:#d8d8d8;
  max-width:475px;

`




export default About
