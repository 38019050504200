import React, {  useState } from "react";
import styled from 'styled-components';
import TitleHome from "../../../components/TitleHome";
import { DataSteps } from "../constants/index";


const Buy = () => {

    const renderItem = (element) => {
        return (
          <ItemProfit>

              <ContentItem>
                  <TitleItem>{element.title}</TitleItem>
                  <DrepItem>{element.dreption}</DrepItem>
              </ContentItem>
          </ItemProfit>
        );
      };



    return (
        <Box_home>
            <TitleHome title="Steps for you to become a part of us" drep=""/>
            {/* <ListCom>{DataSteps.map((item) => renderItem(item))}</ListCom> */}
            <ListCom>
                <ItemProfit>
                  <ContentItem>
                      <TitleItem>Phase 1</TitleItem>
                      <DrepItem>+Build community</DrepItem>
                      <DrepItem>+Setup user interface </DrepItem>
                      <DrepItem>+Plan marketing campaign </DrepItem>
                  </ContentItem>
              </ItemProfit>
              <ItemProfit>
                  <ContentItem>
                      <TitleItem>Phase 2</TitleItem>
                      <DrepItem>+Launch Presale</DrepItem>
                      <DrepItem>++List Dex (Uniswap)</DrepItem>
                  </ContentItem>
              </ItemProfit>
              <ItemProfit>
                  <ContentItem>
                      <TitleItem>Phase 3</TitleItem>
                      <DrepItem>+Gain 5000 wallet holders</DrepItem>
                      <DrepItem>+Build Staking</DrepItem>
                      <DrepItem>+Plan listing Cex</DrepItem>
                  </ContentItem>
              </ItemProfit>
              <ItemProfit>
                  <ContentItem>
                      <TitleItem>Phase 4</TitleItem>
                      <DrepItem>+Launch Goatbase Clubs</DrepItem>
                      <DrepItem>+Enhance user interface</DrepItem>
                      <DrepItem>+Develop swap features</DrepItem>
                  </ContentItem>
              </ItemProfit>
            </ListCom>

        </Box_home>
    );
  }







  const Box_home = styled.section`
    position: relative;
    padding:50px 0;
    width:100%;
    /* background: url('./bgrm.png'); */
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    min-height:80vh;
`

const ItemProfit = styled.div`
    margin-bottom:25px;
    max-width:850px;
    width:100%;
    background:url('./buygr.svg');
    transition: all .2s ease;
    box-shadow: 0 3px 20px 0 rgb(0 0 0 / 12%);
    margin-right:15px;
    border-radius:5px;
    text-align: center;
    cursor: pointer;
    padding: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Icon = styled.div`
  height: 65px;
  width: 65px;
  color: #9629e6;
  text-align: center;
  box-shadow: 0 4px 15px 0 rgb(52 27 159 / 10%);
  border-radius: 4px;
  transition: all .3s ease-in-out;
  margin-bottom: 0;
  margin-right: 30px;
  background: #fff;
  padding: 15px;
  margin:0 auto;

  img{
      width:100%;
  }

`

const ContentItem = styled.div`
  position: relative;
`

const TitleItem = styled.p`
  color:#9629e6;
  font-weight:600;
  margin-bottom:0px

`

const DrepItem = styled.p`
  color:#fff;
`

const ListCom = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`





export default Buy;
