import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
function MenuPrograming() {
  const listPlatForm = [
    {
      title: "React.js Development",
      url: "react-js-development",
    },
    {
      title: "Node.js Development",
      url: "node-js-development",
    },
    {
      title: "JavaScript Developers",
      url: "javaScript-developers",
    },
    {
      title: "Swift Development",
      url: "swift-development",
    },
    {
      title: "Angular Development",
      url: "angular-development",
    },
    {
      title: "Python Development",
      url: "python-development",
    },
    {
      title: "C and C++",
      url: "/",
    },
    {
      title: "Ruby",
      url: "/",
    },
    {
      title: "Solidity",
      url: "/",
    },
    {
      title: "GO",
      url: "/",
    },
  ];
  function RenderListPlatForm() {
    return listPlatForm.map((item, idx) => {
      return (
        <ItemWrapper key={idx}>
          <NavLink className="item__platform" to={item.url}>
            {item.title}
          </NavLink>
        </ItemWrapper>
      );
    });
  }
  return <ListWrapper>{RenderListPlatForm()}</ListWrapper>;
}
const ListWrapper = styled.ul`
  display: flex;
  justify-content: flex-start;
  // align-items: center;
  padding-left: 0px;
  list-style: none;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
`;
const ItemWrapper = styled.li`
  width: 20%;
  padding: 10px 10px 0px 10px;
  color: #ffffff;
  display: block;
  height: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  a.item__platform {
    color: #ffffff;
    text-decoration: none;
    &:hover {
      color: #b7b6b6 !important;
    }
  }
  @media screen and (max-width: 1025px) {
    width: 30%;
  }
`;
export default MenuPrograming;
