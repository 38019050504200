import React, {  useState, useEffect } from "react";
import styled from 'styled-components';
import { DataAbout } from "../constants/index";
import TitleHome from "../../../components/TitleHome";
import { Container, Row, Col } from "reactstrap";
import 'ti-icons/css/themify-icons.css';
import AOS from "aos";
import "aos/dist/aos.css";

const Excellents = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Thời gian animation
      easing: "ease-in-out", // Hiệu ứng easing
      once: true, // Chạy 1 lần
    });
  }, []);

    return (
        <Box_home>
          <Container>
          <TitleHome stlef="color-title2" title="Excellents Trading Transaction With BIGSTARS" drep="More reasons to trade short-term options with BIGSTARS."/>
            <GroupAbout>
                <Content data-aos="fade-right">
                    <ItemExe>
                      <div className="boxicH"><i className="ti-lock"></i></div>
                      <div className="contentIc">
                        <h4>Safe and Secure</h4>
                        <p>Start trading with low capital- only $50. Low transaction costs.</p>
                      </div>
                    </ItemExe>
                    <ItemExe>
                    <div className="boxicH"> <i className="ti-world"></i></div>
                      <div className="contentIc">
                        <h4>Multi-language</h4>
                        <p>Support multi-language interface.</p>
                      </div>
                    </ItemExe>
                    <ItemExe>
                    <div className="boxicH"> <i className="ti-crown"></i></div>
                      <div className="contentIc">
                        <h4>Ease to use</h4>
                        <p>Hight profit, not manipulated</p>
                      </div>
                    </ItemExe>
                    <ItemExe>
                    <div className="boxicH"> <i className="ti-headphone-alt"></i></div>
                      <div className="contentIc">
                        <h4>24/7 Support</h4>
                        <p>Trade 24/7 anytime, anywhere with many attractive markets FOREX, CRYPTO.</p>
                      </div>
                    </ItemExe>

                  </Content>
                  <Thumbnail data-aos="fade-up">
                    <img src="./mbt.svg" alt=""/>
                  </Thumbnail>
                  <Content data-aos="fade-left">
                    <ItemExe>
                    <div className="boxicH"> <i className="ti-layout-media-right"></i></div>
                      <div className="contentIc">
                        <h4>Easy and reliable trade</h4>
                        <p>Traders can earn passive income by helping other members trade.</p>
                      </div>
                    </ItemExe>
                    <ItemExe>
                      <div className="boxicH"> <i className="ti-star"></i></div>
                      <div className="contentIc">
                        <h4>High bonus policy</h4>
                        <p>Bonus policy and attractive promotions</p>
                      </div>
                    </ItemExe>
                    <ItemExe>
                      <div className="boxicH"> <i className="ti-time"></i></div>
                      <div className="contentIc">
                        <h4>Convenient and quick withdrawals</h4>
                        <p>High liquidity, fast deposit and withdrawal by Blockchain technology.</p>
                      </div>
                    </ItemExe>
                    <ItemExe>
                      <div className="boxicH"> <i className="ti-palette"></i></div>
                      <div className="contentIc">
                        <h4>Multi-platform</h4>
                        <p>Trading platform suitable for all devices.</p>
                      </div>
                    </ItemExe>

                  </Content>
              </GroupAbout>
              </Container>
        </Box_home>
    );
  }







const Box_home = styled.section`
  position: relative;
  padding:50px 0;
  width:100%;
  background: url('./rb8.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index:0;

  /* ::before{
    position :absolute;
    top:0;
    z-index:-1;
    content:'';
    width:100%;
    height:100%;
    background-image: linear-gradient(to right,rgb(36 36 37 / 84%),rgb(8 8 10 / 81%),rgb(14 13 16 / 82%));
  } */
`






const ItemExe = styled.div`
  display: flex;
  padding: 0;
  gap:25px;
  color: #fff;

  .boxicH{
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eccb72;
    color: #000000;
    font-weight:800;
    -webkit-box-shadow: 0px 0px 20px 2px #ffca00;
  }
  .contentIc{
    width: calc(100% - 50px);

    h4{
      font-size:18px;
      background: -webkit-linear-gradient(top, rgb(255 175 25), rgb(247 191 52 / 79%) 50%, rgba(254, 224, 141, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }
    p{
      font-size:14px;
    }
  }

  @media (max-width: 1025px) {
    width:100%;
  }

`



const Thumbnail = styled.div`

  width: 32%;

  img{
    width:100%;
    max-width:260px;
    transition: all .2s ease;
    cursor: pointer;
    display: block;
    box-shadow: 1px -1px 20px 0px #75899e;
    border-radius: 30px;
    &:hover{
      scale:1.05;
    }
  }

  @media (max-width: 1200px) {
    max-width:100%;
    text-align: center;
    margin:auto;
    img{
      margin: auto;
    }
  }
  @media (max-width: 770px) {
    width:100%;
    img{
      max-width:70%;
      margin: auto;
    }
  }
`





const Content = styled.div`
  position: relative;
  width: 32%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  gap:25px;

  @media (max-width: 1025px) {
    width:100%;
    gap:10px;
    flex-wrap: wrap;
  }

`

const TitleItem = styled.h3`
  color:#fff;
  font-weight:600;
  margin-bottom:30px;
  text-align:center;
`




const GroupAbout = styled.div`
  display: flex;
  align-items: center;
  gap:15px;
  flex-wrap: wrap;

  @media (max-width: 770px) {
    flex-wrap: wrap;
  }
`








export default Excellents;
