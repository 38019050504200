import React, {  useState } from "react";
import styled from 'styled-components';
import TitleHome from "../../../components/TitleHome";
import { DataPackage } from "../constants/index";


const Packages = () => {

    const renderItem = (element) => {
        return (
          <ItemProfit>
                  <TitleItem>{element.title}</TitleItem>
                  <img src={element.icon} alt="" />
                  <DrepItem>{element.dreption}</DrepItem>
                  <Price>{element.price}</Price>
                  <BtnPrice  href="/">Purchase now</BtnPrice>
          </ItemProfit>
        );
      };



    return (
        <Box_home>
            <TitleHome title="Affordable Pricing and Packages Choose your Best One" drep="Distinctively brand cutting-edge imperatives through synergistic strategize orthogonal relationships whereas infrastructures customize low-risk high-yield processes rather than user friendly."/>
            <ListCom>{DataPackage.map((item) => renderItem(item))}</ListCom>
        </Box_home>
    );
  }







  const Box_home = styled.section`
    position: relative;
    padding:45px 0;
    width:100%;
    background:#f4f7fa;
    // background-image:url('./bgr-sim.svg')
`

const ItemProfit = styled.div`
    margin-bottom:15px;
    max-width:350px;
    width:100%;
    background:#fff;
    border: 1px solid #eff2f7;
    transition: all .2s ease;
    box-shadow: 0 3px 20px 0 rgb(0 0 0 / 12%);
    margin-right:15px;
    border-radius:5px;
    text-align: center;
    cursor: pointer;
    padding:45px;

    :hover{
        will-change: transform;
        background: #6730e3;
        color: #ffffff;
        margin-top:-10px;
    }

    img{
      max-width:65px;
      width:100%;
      margin:0 auto;
    }

`

const BtnPrice = styled.a`
  border-radius: 30px;
  color: #6730e3;
  border: 2px solid #6730e3;
`

const Price = styled.p`
  color: #9629e6;
  font-size:28px;
  margin-bottom:10px
`

const TitleItem = styled.p`
  color:#9629e6;
  font-weight:600;
  margin-bottom:5px

`

const DrepItem = styled.p`
  color:##707070;
`

const ListCom = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`

export default Packages;
