import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useWindowScroll } from "react-use";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./view/Home";
import Login from "./view/Login";
import ForgotPassword from './view/Login/components/ForgotPassword'
import Register from './view/Login/components/Register'
import About from "./view/About";
import Wallet from "./view/Wallet/Wallet";











const App = () => {
  let [menuBg, setMenuBg] = useState(false);
  const { y: pageOffset } = useWindowScroll();
  useEffect(() => {
    if (pageOffset > 70) {
      setMenuBg(true);
    } else {
      setMenuBg(false);
    }
  }, [pageOffset]);
  return (

    <StyledScreen>
      <Router>
        <Header styleBg={menuBg} />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/forgotpassword" exact>
            <ForgotPassword/>
          </Route>
          <Route path="/register" exact>
            <Register/>
          </Route>
          <Route path="/about" exact>
            <About/>
          </Route>
          <Route path="/wallet" exact>
            <Wallet/>
          </Route>
        </Switch>
        <Footer />
      </Router>
    </StyledScreen>
  );
};
const StyledScreen = styled.div`
  position: relative;
  overflow: hidden;
`;

export default App;
