import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'


const FormLogin = () => {
  return (
    <Box_Acount>
    <FormAcount>
      <h3>Login</h3>
      <p>Welcome back! Log In with your Email or Phone number</p>
      <form className="form-ac">
            <label>Email</label>
            <input type="text" name="email" pattern="[^ @]*@[^ @]*" placeholder="Name@address.com" required/>
            <label>Password</label>
            <input type="password" name="password" placeholder="Password" required/>
            <p><a href="/forgotpassword">Forgot Password</a></p>
            <br/>
            <BtnPrice type="submit">Sign in</BtnPrice>
        </form>
        <br/>
        <p>Don’t have an account? <a href="/register">Register Acount</a></p>
    </FormAcount>
 </Box_Acount>
  )
}




const Box_Acount = styled.div`
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1rem 3rem rgb(217 184 109 / 82%);
  background: #fff;
  padding: 25px;
  border-radius:12px;

  .form-ac{
      input{
        display: block;
        margin-bottom:15px;
        border: 1px solid #d5d5d5;
        border-radius:15px;
        font-size:16px;
        padding:0 25px;
        width:100%;
        height:40px;
        outline:none;
      }
  }
  
`


const FormAcount = styled.div`
  padding:50px;
  width: 100%;
  

  @media screen and (max-width:770px){
    padding:20px;
  }


  h3{
    font-size:28px;
    margin-bottom:7px;
    color:#000;
  }
  p{
    font-size:18px;
    margin-bottom:7px;
    color:#d5d5d5;
  }
 
 
`


const BtnPrice = styled.button`
  border-radius: 30px;
  color: #6730e3;
  border: 2px solid #6730e3;
  max-width:310px;
  margin: 0 auto;
  width:100%;
  height:50px;
  display:flex;
  justify-content:center;
  align-items: center;
  background: #25b91b;
  border-color: #ffffff;
  color: #fff;
  border-radius: 30px;

  :hover{
    opacity:0.8;
  }


  @media (max-width: 770px) {
    margin:0 auto;
  }
`


export default FormLogin
