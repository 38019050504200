import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";


function MenuTechnology() {
  const listPlatForm = [
    {
      title: "Artificial Intelligence (AI) and Machine Learning",
      url: "artificial-intelligence-ai-and-machine-learning",
    },
    {
      title: "Cyber Security",
      url: "cyber-security",
    },
    {
      title: "Internet of Things (IoT)",
      url: "internet-of-things",
    },
    {
      title: "Quantum Computing",
      url: "quantum-computing",
    },
    {
      title: "DevOps",
      url: "devOps",
    },
    {
      title: "Blockchain",
      url: "blockchain",
    },
    {
      title: "Health Tech",
      url: "health-tech",
    },
    {
      title: "Cloud Computing",
      url: "cloud-computing",
    },
    {
      title: "Big Data",
      url: "big-data",
    },
    {
      title: "Virtual Reality and Augmented Reality",
      url: "virtual-reality-and-augmented-reality",
    },

  ];
  function RenderListPlatForm() {
    return listPlatForm.map((item, idx) => {
      return (
        <ItemWrapper key={idx}>
          <NavLink className="item__platform" to={item.url}>
            {item.title}
          </NavLink>
        </ItemWrapper>
      );
    });
  }
  return <ListWrapper>{RenderListPlatForm()}</ListWrapper>;
}
const ListWrapper = styled.ul`
  display: flex;
  justify-content: flex-start;
  // align-items: center;
  padding-left: 0px;
  list-style: none;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
`;
const ItemWrapper = styled.li`
  width: 20%;
  padding: 10px 10px 0px 10px;
  color: #ffffff;
  display: block;
  height: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  a.item__platform {
    color: #ffffff;
    text-decoration: none;
    &:hover {
      color: #b7b6b6 !important;
    }
  }
  @media screen and (max-width: 1025px) {
    width: 30%;
  }
`;
export default MenuTechnology;
