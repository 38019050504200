import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DrawerMobile from "../../DrawerMobile";
import Overlay from "../../OverLay";
import HeaderContentNav from "./Component/HeaderContentNav";
function HeaderContentRight(props) {
  let { styleBg } = props;
  let [showMenuRes, setShowMenuRes] = useState(false);
  let [changeMenu, setchangeMenu] = useState(false);
  let [showDrawerMenu, setShowDrawerMenu] = useState(false);
  function handleClick() {
    setShowMenuRes(true);
    setShowDrawerMenu(true);
  }
  //Xu ly Overlay tren PC
  function handleHideMenuRes(data) {
    setShowMenuRes(data);
  }
  //Xy ly An/Hien Drawer tren Mobile
  function HandleHideDrawer(data) {
    setShowDrawerMenu(data);
  }
  useEffect(() => {
    if (window.innerWidth < 1025) {
      //An overlay khi man hinh duoi 1024
      setchangeMenu(true);
    } else {
      setchangeMenu(false);
    }
  });
  return (
    <ContentRight>

        <DrawerMobile
          showDrawerMenu={showDrawerMenu}
          HandleHideDrawer={HandleHideDrawer}
        />
      <HeaderContentNav />
      <SideMenuToggle onClick={handleClick} styleBg={styleBg}>
        <SpanNav />
        <SpanNav />
        <SpanNav />
      </SideMenuToggle>
    </ContentRight>
  );
}
const ContentRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
  width:calc(100% - 160px) @media screen and (max-width: 1025px) {
    justify-content: center;
  }
`;

const SideMenuToggle = styled.div`
  display:none;
  margin-left: 10px;
  font-size: 18px;
  color: #000000;
  transition: all 0.3s ease;
  width: 28px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 99;
  transform: rotate(180deg);
  -webkit-transition: all linear 300ms;
  &:hover span:nth-child(1),
  &:hover span:nth-child(3) {
    width: 60%;
    transition: all linear 300ms;
  }

  @media screen and (max-width: 1025px) {
    right: ${(props) => (props.styleBg ? "40px" : "100px")};
    display:block;
  }

  @media screen and (max-width: 737px) {
    top: 30px;
  }

  @media screen and (max-width: 476px) {
    right: 30px;
  }
`


const SpanNav = styled.span`
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #fff;
  position: relative;
  top: 50%;
  margin-top: 6px;
  right: 0;
`;

export default HeaderContentRight;
