import React, { useState } from "react";
import styled from "styled-components";

const ButtonHome = ({ title}) => {
  return (
        <BtnPrice  href="login">{title}</BtnPrice>
  ) 
 
  ;
};



const BtnPrice = styled.a`
  border-radius: 30px;
  color: #6730e3;
  max-width:210px;
  height:50px;
  display:flex;
  justify-content:center;
  align-items: center;
  background: #ff444f;
  border-color: #ffffff;
  color: #fff;
  border-radius: 30px;
  margin: 0 auto;


  @media (max-width: 770px) {
    margin:0 auto;
  }
`

export default ButtonHome;
