import React, {  useState, useEffect } from "react";
import styled from 'styled-components';
import { DataAbout } from "../constants/index";
import TitleHome from "../../../components/TitleHome";
import { Container, Row, Col } from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";


const ItWork = () => {

  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

    return (
        <Box_home>
          <Container>
            <TitleHome title="How it work?" drep=""/>
            <GroupAbout>
                  <Thumbnail data-aos="fade-right">
                    <img src="./abouts.png" alt="abouts"/>
                  </Thumbnail>
                  <Content data-aos="fade-left">
                    <h3><b>BIGSTARS Trading Plarform</b></h3>
                    <p>BIGSTARS is a trading platform that applies the BINARY OPTION platform, which is currently the most transparent trading platform in the world, participants on the exchange will trade by themselves, not by winning or losing transactions with brokers of the exchange. or Market Makers</p>
                    <p>We are focused on serving our customers responsibly with the latest innovations in technology. instruments on forex and crypto exchanges, research tools to support transactions on the BO and blockchain platforms.</p>
                  </Content>
            </GroupAbout>
            <GroupAbout>
                  <Content data-aos="fade-right">
                      <h3><b>BIGSTARS Trading Plarform</b></h3>
                      <p>BIGSTARS is a trading platform that applies the BINARY OPTION platform, which is currently the most transparent trading platform in the world, participants on the exchange will trade by themselves, not by winning or losing transactions with brokers of the exchange. or Market Makers</p>
                      <p>We are focused on serving our customers responsibly with the latest innovations in technology. instruments on forex and crypto exchanges, research tools to support transactions on the BO and blockchain platforms.</p>
                  </Content>
                  <Thumbnail data-aos="fade-left">
                    <img src="./abouts1.png" alt="abouts"/>
                  </Thumbnail>
            </GroupAbout>
          </Container>
        </Box_home>
    );
  }







const Box_home = styled.section`
  position: relative;
  padding:100px 0;
  width:100%;
  /* background: url('./bga3.png'); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  .group-link-contract{
    max-width: 150px;
    width: 100%;
    border-radius: 40px;
    padding: 5px;
    background: #3F51B5;
  }

  @media (max-width: 10250px) {
    padding:40px 0;
  }

`






const ListCom = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`

const Thumbnail = styled.div`
  max-width:1100px;
  width: 100%;

  img{
    width:100%;
  }

  @media (max-width: 770px) {
    max-width:100%;
    margin-right:0;
  }
`




const Content = styled.div`
  position: relative;
    width: 100%;
    color: #000;
    padding: 50px;
    border-radius: 50px;

  @media (max-width: 770px) {
    width:100%;
    padding:5px;
  }

  h4{
    text-align: justify;
    font-size:18px;
  }

`



const DrepItem = styled.p`
  color:##707070;
`


const GroupAbout = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1025px) {
    flex-wrap: wrap;
  }
`








export default ItWork;
