import React, { useState } from "react";
import styled from "styled-components";

const Language = () => {
  return (
    <div>
       <select id="country" name="country">
    <option>select country</option>
    <option value="AX">Aland Islands</option>
    <option value="AL">Albania</option>
    <option value="AD">Andorra</option>
    <option value="AT">Austria</option>
    <option value="BY">Belarus</option>
    <option value="BE">Belgium</option>
    <option value="BA">Bosnia and Herzegovina</option>
    <option value="BG">Bulgaria</option>
    <option value="HR">Croatia</option>
    <option value="CZ">Czech Republic</option>
    <option value="DK">Denmark</option>
    <option value="EE">Estonia</option>
    <option value="FO">Faroe Islands</option>
    <option value="FI">Finland</option>
    <option value="FR">France</option>
   
</select>
    </div>

  ) 
 
  ;
};

const ContentTitle = styled.h3`
    margin-bottom: 15px;
    font-size: 32px;
    text-align:center;
    font-weight: bold;
    width: 100%;

    @media(max-width:770px){
      font-size:24px;
    }

    img{
      max-width:30px;
      width: 100%;
      margin-top:-7px;
    }

`

const Dreption = styled.p`
    font-size: 16px;
    text-align:center;
    color:#707070;
    width: 100%;
    max-width:700px;
    margin:0 auto;
    margin-bottom: 25px;

    @media(max-width:770px){
      font-size:24px;
    }
   
`

export default Language;
