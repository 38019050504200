import React, { useState } from "react";
import styled from "styled-components";

const TitleHome = ({ title,drep,stlef, drepbi}) => {
  return (
    <div>
        <ContentTitle className={stlef}>{title}</ContentTitle>
        <Dreption className={drepbi}>{drep}</Dreption>
    </div>

  )

  ;
};

const ContentTitle = styled.h3`
    margin-bottom: 15px;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    text-align:center;
    font-weight: bold;
    color:#000000;
    width: 100%;

    @media(max-width:770px){
      font-size:28px;
    }

    img{
      max-width:30px;
      width: 100%;
      margin-top:-7px;
    }
    .color-title2{
      background: -webkit-linear-gradient(top, rgb(255 175 25), rgb(247 191 52 / 79%) 50%, rgba(254, 224, 141, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }

`

const Dreption = styled.p`
    font-size: 16px;
    text-align:center;
    color:#fff;
    width: 100%;
    max-width:700px;
    margin:0 auto;
    margin-bottom: 25px;

    @media(max-width:770px){
      font-size:24px;
    }

    &.clnih{
      color:#000000;
    }

`

export default TitleHome;
