import React, {  useState } from "react";
import styled from 'styled-components';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import {CopyToClipboard} from 'react-copy-to-clipboard';


const TabAddres = () => {

  
// Tab deposit
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }


  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
              BEP20(BSC)
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            ERC20
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
            <Warning><b>Reminder:</b>For the safety of your funds, please confirm again that the blockchain you wish to use is BSC.</Warning>
            <Text>Your <span>USDT</span> depositing address</Text>
            <ThumQr><img src="./qr.png" alt="QR"/></ThumQr>
            <Text>0x84FAd707f81A961973eFafB7B64f053fA31bE8b8</Text>
            <FormUser method="" action>
              <p><label>User ID *</label></p>
              <input type="text" required name="id" placeholder="ID-xxxxx"/>
              <p><label>USDT Amount *</label></p>
              <input type="text" required name="amount" placeholder="xxx" />
              <BtnPrice type="submit">Send</BtnPrice>
            </FormUser>
        </TabPane>
        <TabPane tabId="2">
         UPDATE!
        </TabPane>
      </TabContent>
    </div>
  );
}





const Box_home = styled.section`
    position: relative;
    padding:45px 0;
    width:100%;
`



const Warning = styled.p`
    font-size:14px;
    color:#f95318;
    margin: 15px 0;
`

const Text = styled.p`
    text-align:center;
`

const ThumQr = styled.p`
  margin: 10px 0;
  

  img{
    max-width: 180px;
    width: 100%;
    margin: 0 auto;
    display: block;
  }
`

const FormUser = styled.form`
  border: solid 1px #404040;
  border-radius: 10px;
  padding: 20px;

  input{
    width: 100%;
    outline: none;
    padding: 5px;
    border-radius: 5px;
  }
  p{
    margin-bottom:0;
    margin-top: 10px;
  }
  
  
`


const BtnPrice = styled.button`
  border-radius: 30px;
  color: #6730e3;
  border: 2px solid #6730e3;
  max-width:150px;
  margin: 0 auto;
  width:100%;
  height:45px;
  display:flex;
  justify-content:center;
  align-items: center;
  background: #25b91b;
  border-color: #ffffff;
  color: #fff;
  border-radius: 30px;
  margin-top: 15px;

  :hover{
    opacity:0.8;
  }


  @media (max-width: 770px) {
    margin:0 auto;
  }
`


export default TabAddres;
