import React, { useState, useEffect, } from "react";
import styled from 'styled-components'
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumbs";
import PopupDeposit from "../../components/PopupDeposit";


const Wallet = () => {



  return (
    <Box_home>
      <Breadcrumbs title="Deposit" text="AppCo is a trading platform" />
      <Container>
      <Content>
          <GroupCoin>
              <Coin><img src="./m-usdt.svg" alt=""/>USDT</Coin>
              <Quantity>10 <span>usdt</span></Quantity>
              <PopupDeposit/>
              <Quantity>widraw</Quantity>
          </GroupCoin>
      </Content>
      </Container>
  </Box_home>
  )
}

const Box_home = styled.section`
  position: relative;
  
  
`


const Content = styled.div`
  padding:50px 0;
`

const Quantity = styled.p`
  font-size:16px;
  color:#000;
  margin:0;
  width:200px;
  text-align: center;

  span{
    color:#544f4f;
    font-size:14px;
  }
`




const GroupCoin = styled.div`
  display:flex;
  align-items: center;
  border:solid 1px #d5d5d5;
  border-radius:5px;
  padding: 5px 10px;
`

const Coin = styled.div`
  display:flex;

  img{
    max-width:20px;
    width:100%;
    margin-right:5px;
  }

`


const Valuation = styled.div`
  display:flex;

`





export default Wallet
