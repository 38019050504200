import React from "react";
import styled from "styled-components";

const Button = (props) => <ButtonA href="http://">{props.children}</ButtonA>;

const ButtonA = styled.a`
  border-radius: 50px;
  padding: 9px 34px !important;
  display: inline-block;
  line-height: 25px;
  font-weight: 600;
  position: relative;
  text-align: center;
  transition: ease all 0.5s;
  font-size: 14px;
  background: #ffffff;
  border-color: #222d38;
  color: #222d38;
  border: solid 1px;
  z-index: 999;

  &:hover {
    color: #fff;
    text-decoration: none;
    z-index: 10;
  }

  &:hover:before {
    transform: scaleX(1);
    border-color: #66c904;
  }

  :before {
    content: "";
    position: absolute;
    background: #66c904;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 38px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
`;

export default Button;
