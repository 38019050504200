import React, {  useState, useEffect } from "react";
import styled from 'styled-components';
import TitleHome from "../../../components/TitleHome";
import { DataPackage } from "../constants/index";
import AOS from "aos";
import "aos/dist/aos.css";


const AppCo = () => {

    const renderItem = (element) => {
        return (
          <ItemProfit>
                  <img src={element.icon} alt="" />
                  <TitleItem>{element.title}</TitleItem>
                  <DrepItem>{element.dreption}</DrepItem>
                  {/* <Price>{element.price}</Price> */}
                  {/* <BtnPrice  href="/">Purchase now</BtnPrice> */}
          </ItemProfit>
        );
      };

      useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: "ease-in-out",
          once: true,
        });
      }, []);


    return (
        <Box_home>
            <TitleHome title="4 easy startup steps" drep=""/>
            <ListCom data-aos="fade-up" >{DataPackage.map((item) => renderItem(item))}</ListCom>
        </Box_home>
    );
  }







const Box_home = styled.section`
    position: relative;
    padding:100px 0;
    width:100%;
    background: #ffffff;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 770px) {
      padding:60px 0;
  }

`

const ItemProfit = styled.div`
    max-width:255px;
    width:100%;
    background:url('./our-mission-2.jpg') no-repeat center center / cover;
    border: 1px solid #eff2f7;
    box-shadow: 0 3px 20px 0 rgb(0 0 0 / 12%);
    border-radius:15px;
    text-align: left;
    cursor: pointer;
    padding:25px;
    transition: all .2s ease;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.12);

    :hover{
        background: -webkit-linear-gradient(top, #000000, #2b240f, #332203);
        color: #ffffff;
    }

    img{
      max-width:32px;
      width:100%;
    }


`

const GroupAbout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;

  @media (max-width: 770px) {
    flex-wrap: wrap;
  }
`


const BtnPrice = styled.a`
  border-radius: 30px;
  color: #6730e3;
  border: 2px solid #6730e3;
`


const TitleItem = styled.p`
  font-weight:600;
  margin-bottom:5px;
  font-size:22px;
  background:-webkit-linear-gradient(top, rgb(255 175 25), rgb(247 191 52 / 79%) 50%, rgba(254,224,141,1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

`

const DrepItem = styled.p`
  color:#ffffff;
`

const ListCom = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
`

export default AppCo;
