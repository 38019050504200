export const DataAbout = [
  {
    icon: "./ab1.png",
    title: "Our Pricing Package",
    dreption: "Proactively syndicate open-source e-markets after low-risk high-yield synergy. Professionally simplify visionary technology.",
  },
  {
     icon: "./ab2.png",
    title: "Our Pricing Package",
    dreption: "Intrinsicly aggregate cutting-edge internal or 'organic' sources through pandemic manufactured products. Synergistically",
  },
  {
     icon: "./ab3.png",
    title: "Our Pricing Package",
    dreption: "Completely administrate empowered e-tailers after extensive experiences. Holisticly leverage existing quality networks.",
  },


];







export const DataSteps = [
  {
    title: "PHASE 1",
    dreption: "+Build community +Setup user interface + Plan marketing campaign",
  },
  {
    title: "PHASE 2",
    dreption: "Fast deposit and withdrawal using popular crypto deposit portals",
  },
  {
    title: "PHASE 3",
    dreption: "Double your account in just 30s",
  },


];

export const Datasocial = [
  {
    icon: "./tx.png",
    link: "https://twitter.com/goatbases",
    dreption: "Register by Email and trade for free",
  },
  {
     icon: "./tl.png",
     link: "/",
    dreption: "Fast deposit and withdrawal using popular crypto deposit portals",
  },


];







export const DataPackage = [
  {
    icon: "./fp1.png",
    title: "Create Account",
    dreption: "Register by Email and trade for free.",
    price: "$29"
  },
  {
    icon: "./fp2.png",
    title: "Deposit",
    dreption: "Fast depositusing popular crypto deposit portals.",
    price: "$145"
  },
  {
    icon: "./fp3.png",
    title: "Flash",
    dreption: "Double your account in just 30 second.",
    price: "$249"
  },
  {
    icon: "./fp4.png",
    title: "Manage",
    dreption: "Earn money by predicting property prices.",
    price: "$249"
  },

];