import React, {  useState } from "react";
import styled from 'styled-components';
import ButtonHome from "../../../components/ButtonHome";
import TitleHome from "../../../components/TitleHome";


const Referral = () => {


    return (
        <Box_home>
            <Title>15% Referral Commission</Title>
            <Drep>Regular members who have an active deposit can participate in our affilire program which givesfor their active referrals</Drep>
            <ButtonHome her={'/login'} title="Purchase now" />
        </Box_home>
    );
  }







  const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    padding:100px 0;
    width:100%;
    background: url("/trade.jpeg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index:0;
  

  ::before{
    position :absolute;
    top:0;
    z-index:-1;
    content:'';
    width:100%;
    height:100%;
    background-image: linear-gradient(to right,rgb(36 36 37 / 84%),rgb(8 8 10 / 81%),rgb(14 13 16 / 82%));
  }
`


const Drep = styled.p`
  margin-bottom: 25px;
  font-size: 16px;
  text-align:center;
  color:#fff;
  width: 100%;

  @media(max-width:770px){
    font-size:24px;
  }

`

const Title = styled.h3`
  margin-bottom: 15px;
  color:#fff;
  font-size: 32px;
  text-align:center;
  font-weight: bold;
  width: 100%;

  @media(max-width:770px){
    font-size:24px;
  }

`




export default Referral;
