import React, { useState,state } from "react";
import styled from "styled-components";
import LanguageSwitcherSelector from "./Component/LanguageSwitcherSelector"

const LanguageTest = () => {


 
  const translations = {
    'en': {
      'header': 'Spring',
      'paragraf': 'Hello',
      'lang-choice': 'Choose your language:'
    },
    'deu': {
      'header': 'Frühling',
      'paragraf': 'Hallo',
      'lang-choice': 'Wählen Sie Ihre Sprache:'
    },
    'rus': {
      'header': 'Весна',
      'paragraf': 'Привет',
      'lang-choice': 'Выберите ваш язык:'
    }
  }
  
  const getTranslation = (lang, text) => {
    return translations[lang][text];
  }
 

 

  return (
    <div>
       <LanguageSwit>
        <div className="flower"></div>
        <p>
          {getTranslation(this.state.lang, 'paragraf')}
        </p>
        <h2>
          {getTranslation(this.state.lang, 'header')}  
        </h2>
        <p className="small">
          {getTranslation(this.state.lang, 'lang-choice')}
        </p>
        <LanguageSwitcherSelector 
          lang={this.state.lang}
          handleChangeLanguage={this.changeLanguageHandler}
        />
      </LanguageSwit>
    </div>

  ) 
 
  ;
};

const LanguageSwit = styled.div`
  min-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: radial-gradient(circle, #dfffdb 50%, white 50%);
  background-position-y: -390px;
  margin: auto 0;
  background-size:  100% 800px;
  background-repeat: no-repeat;


`

const Dreption = styled.p`
    font-size: 16px;
    text-align:center;
    color:#707070;
    width: 100%;
    max-width:700px;
    margin:0 auto;
    margin-bottom: 25px;

    @media(max-width:770px){
      font-size:24px;
    }
   
`

export default LanguageTest;
