import React from "react";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";
function ButtonClose() {
  return (
    <WrapperBtnClose>
      <BorderBtn className="activeHover">
        <IoClose color="#ffffff" size="2rem" />
      </BorderBtn>
    </WrapperBtnClose>
  );
}
const WrapperBtnClose = styled.div`
  cursor: pointer;
  &:hover .activeHover {
    border-color: #cacaca;
  }
`;
const BorderBtn = styled.div`
  width: 40px;
  height: 40px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;
export default ButtonClose;
