import React, { useEffect } from "react";
import styled from "styled-components";
import useWow from "../../Wow";

const Nav = () => {
  const { initWow } = useWow();
  useEffect(() => {
    initWow();
  }, []);
  return (
    <Nav_Footer>
      <Nav_Footer_li className="wow fadeInDown">
        <Nav_Footer_link href="/">
          <i className="fa fa-facebook" aria-hidden="true"></i>
        </Nav_Footer_link>
      </Nav_Footer_li>
      <Nav_Footer_li className="wow fadeInUp">
        <Nav_Footer_link href="/">
          <i className="fa fa-twitter" aria-hidden="true"></i>
        </Nav_Footer_link>
      </Nav_Footer_li>
      <Nav_Footer_li className="wow fadeInDown">
        <Nav_Footer_link href="/">
          <i className="fa fa-google-plus" aria-hidden="true"></i>
        </Nav_Footer_link>
      </Nav_Footer_li>
      <Nav_Footer_li className="wow fadeInUp">
        <Nav_Footer_link href="/">
          <i className="fa fa-linkedin" aria-hidden="true"></i>
        </Nav_Footer_link>
      </Nav_Footer_li>
      <Nav_Footer_li className="wow fadeInDown">
        <Nav_Footer_link href="/">
          <i className="fa fa-instagram" aria-hidden="true"></i>
        </Nav_Footer_link>
      </Nav_Footer_li>
      <Nav_Footer_li className="wow fadeInUp">
        <Nav_Footer_link href="/">
          <i className="fa fa-envelope-o" aria-hidden="true"></i>
        </Nav_Footer_link>
      </Nav_Footer_li>
    </Nav_Footer>
  );
};

const Nav_Footer = styled.ul`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 15px 0;
`;
const Nav_Footer_li = styled.li`
  margin: 0 15px;
  list-style: none;
`;

const Nav_Footer_link = styled.a`
  font-size: 16px;
  font-weight: bold;
  color: #99378e;
  display: block;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  border-radius: 50px;
  font-size: 17px;
  border: 1px solid transparent;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background: #4267b2 !important;
    color: #fff !important;
    border: 1px solid #4267b2 !important;
  }

  i {
    color: #fff;
  }
`;

export default Nav;
