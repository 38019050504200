import React, {  useState } from "react";
import styled from 'styled-components';
import TitleHome from "../../../components/TitleHome";
import { Datasocial } from "../constants/index";


const Social = () => {

    const renderItem = (element) => {
        return (
          <ItemProfit>
              <Icon>
                  <img src={element.icon} alt="" />
              </Icon>
              <ContentItem>
                  <TitleItem>{element.title}</TitleItem>
              </ContentItem>
          </ItemProfit>
        );
      };



    return (
        <Box_home>
            <TitleHome title="Join GOATbase Community" drep=""/>
            <ListCom>{Datasocial.map((item) => renderItem(item))}</ListCom>
            <Boximg>
            {/* <img src="./com.png" alt="Goatbase" /> */}
            </Boximg>

        </Box_home>
    );
  }







  const Box_home = styled.section`
    position: relative;
    padding:50px 0;
    width:100%;
    background: url('./bga3.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
`

const ItemProfit = styled.div`
    margin-bottom:15px;
    max-width:150px;
    width:100%;
    transition: all .2s ease;
    /* box-shadow: 0 3px 20px 0 rgb(0 0 0 / 12%); */
    margin-right:15px;
    border-radius:5px;
    text-align: center;
    cursor: pointer;
    padding:25px;
`
const Boximg = styled.div`
  position: relative;
  max-width:1200px;
  width:100%;
  margin: 0 auto;

  img{
    width:100%;
  }
`

const Icon = styled.div`
  height: 65px;
  width: 65px;
  color: #9629e6;
  text-align: center;
  box-shadow: 0 4px 15px 0 rgb(52 27 159 / 10%);
  border-radius: 4px;
  transition: all .3s ease-in-out;
  margin-bottom: 0;
  margin-right: 30px;
  background: #fff;
  padding: 15px;
  margin:0 auto;

  img{
      width:100%;
  }

`

const ContentItem = styled.div`
  position: relative;
`

const TitleItem = styled.p`
  color:#9629e6;
  font-weight:600;
  margin-bottom:5px

`

const DrepItem = styled.p`
  color:##707070;
`

const ListCom = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`


const BtnPrice = styled.a`
  border-radius: 30px;
  color: #6730e3;
  border: 2px solid #6730e3;
  max-width:210px;
  height:50px;
  margin:0 auto;
  display:flex;
  justify-content:center;
  align-items: center;
  background: #ffffff;
  border-color: #ffffff;
  color: #6730e3;
  border-radius: 30px;
`

export default Social;
