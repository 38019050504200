import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

function MenuDropdown() {
  const listPlatForm = [
    {
      title: "Mobile App Development",
      url: "mobile-app-development",
    },
    {
      title: "Custom Product Development",
      url: "custom-product-development",
    },
    {
      title: "Blockchain Development",
      url: "blockchain-development",
    },
    {
      title: "Security Compliance",
      url: "security-compliance",
    },
    {
      title: "Search Engine Optimization",
      url: "seo-serviced",
    },
    {
      title: "IOS Development",
      url: "ios-development",
    },
    {
      title: "Software Design",
      url: "software-design",
    },
    {
      title: "Smart Contact & dApps",
      url: "smart-contact-&-dApps",
    },
    {
      title: "PCI Audits",
      url: "pci-audits",
    },
    {
      title: "On Page SEO",
      url: "on-page-seo",
    },
    {
      title: "PCI Audits",
      url: "pci-audits",
    },
    {
      title: "Android Development",
      url: "android-development",
    },
    {
      title: "Custome Software Development",
      url: "custome-software-development",
    },
    {
      title: "Enterprise Blockchain",
      url: "enterprise-blockchain",
    },
    {
      title: "Vulnerability Scanning",
      url: "vulnerability-scanning",
    },
    {
      title: "Off Page SEO",
      url: "off-page-seo",
    },
    {
      title: "Cross-platform Development",
      url: "Cross-platform-development",
    },
    {
      title: "E-Commerce Web Development",
      url: "e-Commerce-web-development",
    }, {
      title: "Crypto Wallets",
      url: "crypto-wallets",
    },
    {
      title: "Technical SEO",
      url: "technical-seo",
    },

  ];
  function RenderListPlatForm() {
    return listPlatForm.map((item, idx) => {
      return (
        <ItemWrapper key={idx}>
          <NavLink className="item__platform" to={item.url}>
            {item.title}
          </NavLink>
        </ItemWrapper>
      );
    });
  }
  return <ListWrapper>{RenderListPlatForm()}</ListWrapper>;
}
const ListWrapper = styled.ul`
  display: flex;
  justify-content: flex-start;
  // align-items: center;
  padding-left: 0px;
  list-style: none;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
`;
const ItemWrapper = styled.li`
  width: 20%;
  padding: 10px 10px 0px 10px;
  color: #ffffff;
  display: block;
  height: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  a.item__platform {
    color: #ffffff;
    text-decoration: none;
    &:hover {
      color: #b7b6b6 !important;
    }
  }
  @media screen and (max-width: 1025px) {
    width: 30%;
  }
`;
export default MenuDropdown;
