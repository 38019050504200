import React, { useState, useEffect, } from "react";
import styled from 'styled-components'
import { Container, Row, Col } from "reactstrap";
import FormLogin from "./components/FormLogin";


const Login = () => {



  return (
    <Box_home>
      <Container>
        <GroupAcount>
          <Content>
            <TitleAcount>Welcome Back !</TitleAcount>
            <DrepAcount>Keep your face always toward the sunshine - and shadows will fall behind you.</DrepAcount>
          </Content>
          <FormLogin/>
        </GroupAcount>
      </Container>
  </Box_home>
  )
}

const Box_home = styled.section`
  position: relative;
  overflow: hidden;
  min-height:100vh;
  background: url("/images/bn4.jpeg");
  z-index:0;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  
`
const GroupAcount = styled.div`
  padding: 200px 0 100px;
  display: flex;
  width: 100%;

  @media screen and (max-width:1025px){
    flex-wrap:wrap;
    justify-content: center;
    padding: 75px 0 25px;
  }

`

const Content = styled.div`
  max-width:600px;
  width:100%;
`

const TitleAcount = styled.h3`
  font-size:40px;
  color:#fff;
`


const DrepAcount = styled.p`
  font-size:18px;
  color:#d8d8d8;
  max-width:475px;

`




export default Login
