import React from "react";
import styled from "styled-components";
import Text from "./components/Text";
import Nav from "./components/Nav";
import { Container, Row, Col } from "reactstrap";

function Footer() {
  return (
    <StyledFooter>
        <div className="container">
            <LinkFooter>
              <Row xs="1" sm="2" md="4" >
                  <Col >
                      <div className="lgfooter"><a href="/"><img className="logofts" src="./logo.svg" alt="logo" /></a></div>
                      <p className="drepfts">Our real value arises from the mutual benefits you bring onboard. And as always, we endeavor to respond conclusively to all concerns you raise.</p>
                      <p><strong>© 2024 BIGSTAR All rights reserved.</strong></p>
                  </Col>
                  <Col>
                      <h3 className="titleFt">Security & Privacy</h3>
                      <div className="linkfts">
                          <a href="/">Terms and Conditions</a>
                          <a href="/">Risks Disclosure</a>
                          <a href="/">Liability and Indemnity</a>
                      </div>
                  </Col>
                  <Col>
                  <h3 className="titleFt">Support</h3>
                      <div className="linkfts">
                          <a href="/">Terms and Conditions</a>
                          <a href="/">Risks Disclosure</a>
                          <a href="/">Liability and Indemnity</a>
                      </div>
                  </Col>
                  <Col>
                    <h3 className="titleFt">Location</h3>
                        <div className="linkfts">
                            <a href="/">Terms and Conditions</a>
                            <a href="/">Risks Disclosure</a>
                            <a href="/">Liability and Indemnity</a>
                        </div>
                  </Col>
              </Row>
            </LinkFooter>
            <SocialFt>
               <div className="linkfts">
                    <a href="/"><i className="ti-lock"></i></a>
                    <a href="/"><i className="ti-twitter-alt"></i></a>
                    <a href="/"><i className="ti-instagram"></i></a>
                </div>
            </SocialFt>
            <CopyRight>
                <p><strong>Risk Warning:</strong> Trading and investing in digital options involves a significant level of risk and is not suitable and/or appropriate for all clients.</p>
            </CopyRight>
        </div>
    </StyledFooter>

  );
};




const StyledFooter = styled.footer`
  position: relative;
  padding: 5px 0;
  overflow: hidden;
  color:#ece8e8;
  text-align:center;
   background: url('./bgfooter.jpg');
   background-position: center;
  background-repeat: no-repeat;
  background-size: cover;



  @media (max-width: 770px) {

  }

  .line-column{
    padding-top: 25px;
  }
`








const LinkFooter = styled.div`
    display: flex;
    width:100%;
    padding:50px 0 10px 0;
    text-align:left;

    @media (max-width: 770px) {
      flex-wrap: wrap;
    }

    .logofts{
      max-width:120px;
    }
    .titleFt{
      font-size:18px;
      font-weight:bold;
      margin-bottom:20px;
    }
    .drepfts{
      font-size:14px;
      color:#ece8e8;
    }
    .linkfts{
      a{
        width:100%;
        color:#ece8e8;
        display: flex;
        font-size:14px;
        margin-bottom:15px;

        &:hover{
          opacity:.5;
        }
      }
    }
`



const CopyRight = styled.div`
    width:100%;
    text-align:left;
    @media (max-width: 770px) {
      width: 100%;
    }

`

const SocialFt = styled.div`
    width:100%;
    text-align:left;
    @media (max-width: 770px) {
      width: 100%;
    }

    .linkfts{
      margin-bottom:25px;
      a{
        display:inline-flex;
        transition: all .2s ease;
        &:hover{
            scale:1.5;
          }
        i{
          color:#cfa224;
          margin-right:15px;
        }
      }
    }

`



export default Footer;
