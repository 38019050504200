import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import MenuDropdown from "../../../../MenuDropdown";
import { IoMdArrowDropdown } from "react-icons/io";
import { NavLink } from "react-router-dom";
import MenuTechnology from "../../../../MenuTechnology";
import MenuPrograming from "../../../../MenuPrograming";
import Language from "../../../../Languge";
import LanguageTest from "../../../../Languge-test/LanguageTest";



function HeaderContentNav({ showMenuRes }) {


  const handleClick = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const section1Ref = useRef(null);

  // const scrollToSection = () => {
  //   const element = document.getElementById("sectionId");
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };


  return (
    <div className="header__content-nav">
      <NavList className="list-unstyled">
        <MenuItem className="item__parent">
          <NavLink
            className="link__menuHeader"
            activeClassName="active__style"
            exact
            to="/"

          >
            Trade
          </NavLink>
        </MenuItem>
        <MenuItem className="item__parent">
          <NavLink
            className="link__menuHeader"
            activeClassName="active__style"
            exact
            to="/"
            onClick={() => handleClick(section1Ref)}
          >
            Market
          </NavLink>
        </MenuItem>

        <MenuItem className="item__parent">
          <NavLink className="link__menuHeader" activeClassName="active__style" exact to="/">
            About
          </NavLink>
        </MenuItem>
        {/* <MenuItem className="item__parent">
          <NavLink to="/services" className="link__menuHeader service__tech">
            Technology <IoMdArrowDropdown className="dropIcon" />
          </NavLink>
          <BoxWrapper className="box__wp" showMenuRes={showMenuRes}>
            <MenuTechnology />
          </BoxWrapper>
        </MenuItem> */}
        <MenuItem className="item__parent">
          <a className="link__menuHeader btn-login" href="https://trade.bigstars.vip/login">
          Login
          </a>
        </MenuItem>
        <MenuItem className="item__parent">
          <a className="link__menuHeader btn-regis" href="https://trade.bigstars.vip/register">
            Register
          </a>
        </MenuItem>
        {/* <MenuItem className="item__parent">
          <NavLink className="link__menuHeader btn-login" to="/login">
            Login
          </NavLink>
        </MenuItem> */}
        <MenuItem className="item__parent">
            {/* <LanguageTest/> */}
        </MenuItem>
      </NavList>
    </div>
  );
}
const NavList = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (max-width: 1025px) {
    display: none;
  }
`


const MenuItem = styled.li`
  margin: 0px 16px;


  &.item__parent:hover .box__wp {
    display: block;
  }
  .active__style {
    font-weight: bold;
  }

  .btn-regis{
    padding:5px 35px;
    border-radius:25px;
    background:-webkit-linear-gradient(top, #e39a11, rgba(247, 191, 52, 0.5) 50%, rgba(254,224,141,1))!important;
    color:#fff!important;
    border:solid 1px #e39a11;
  }

  .btn-login{
    border:solid 1px #e39a11;
    padding:5px 15px;
    border-radius:25px;
  }

  .link__menuHeader {
    cursor: pointer;
    text-decoration: none !important;
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size:16px;

    &:hover {
    opacity:.5;
  }




  @media screen and (max-width: 1025px) {
    margin: 0px 20px;
  }

  @media screen and (max-width: 1025px) {
    font-size: 18px;
    &.service__item .dropIcon {
      top: 0px;
      left: 0px;
    }
  }

  }
`


export default HeaderContentNav;
