import React from "react";
import styled from "styled-components";

function HeaderContentLeft() {
  return (
    <ContentLeft>
      <LinkLogo href="/">
        <ImgLogo src="./logo1.png" alt="Bigstart" />
      </LinkLogo>
    </ContentLeft>
  );
}
const ContentLeft = styled.div`
    max-width:120px;
    width:100%;
`
const LinkLogo = styled.a`
  max-width: 120px;
  display: block;
  overflow: hidden;

  @media (max-width: 770px) {
    max-width: 70px;
  }
`


const ImgLogo = styled.img`
  width: 100%;
  display: block;
`
export default HeaderContentLeft;
